import routes from "./routes.json";
import main from "./main.json";
import main_content from './main-content.json';
import auth from "./auth.json";
import account from "./account.json";
import crm from "./crm.json";
import hc from "./hc.json";
import cookie from "./cookie.json";
import privacy_policy from "./privacy-policy.json";
import seo from "./seo.json";

const content = {
	routes,
	main,
	main_content,
	auth,
	account,
	crm,
	hc,
	cookie,
	privacy_policy,
	seo,
}

export default content;
